import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/taskTable.scss';
import { nodeColors } from '../../constants/colors';
import AnimationSlide from '../Elements/AnimationSlide';
import StatusMenu from '../StatusMenu';
import Avatar from '../Elements/Avatar';
import { deadlineCal } from '../../helper/functions';
import { DataContext } from '../../context/DataContext';
import dataServices from '../../services/dataServices';
import { colorPanel, floatMenuStatus } from '../../constants/arrays';
import MenuSelectBoard from '../Elements/MenuSelectBoard';
import { layerProps } from '../../constants/objects';
import TagMenu from '../Elements/TagMenu';
import MenuSelectMember from '../Elements/MenuSelectMember';
import MenuMemberList from '../Elements/MenuMemberList';
import DatePicker from '../Elements/DatePicker';
import ProOptionCard from '../ProAccount/ProOptionCard';

function TaskTableCard({
  index,
  dataId,
  setCheckMembers,
  setToastInfo,
  toastInfo,
  updateStatus,
  deleteTask,
  duplicateTask,
  tasksDate,
  type,
}) {
  const { treeData, originData, isProUser } = useContext(DataContext);
  const navigate = useNavigate(null);

  const parentColor = treeData[dataId]?.parent_color || 'BLUE';
  const number = index + 1;

  const user = JSON.parse(localStorage.getItem('user'));
  const isTaskMine = treeData[dataId]?.owner === user.id;

  const taskName = treeData[dataId]?.title[0].text;
  const boardAssignId = treeData[dataId]?.board_id;
  const boardAssign = originData.find((i) => i.id === boardAssignId);
  const boardAssignName = boardAssign?.title[0].text;

  const parentName = boardAssign && treeData[dataId].parent_title[0].text;
  const parentBoard = boardAssignId
    ? originData.find((i) => i.id === treeData[dataId].board_id)
    : null;
  const tagColor =
    colorPanel.find(({ setColor }) => setColor === treeData[dataId]?.color) ||
    'pink';

  const members = treeData[dataId]?.assignments;
  const tagName =
    boardAssignId && parentBoard ? parentBoard[tagColor.color] : 'Tag';

  const status = treeData[dataId]?.status;
  const taskStatus = floatMenuStatus[status];

  const [isDone, setIsDone] = useState(status === 'DONE');
  const [openMenu, setOpenMenu] = useState('');
  const [statusIcon, setStatusIcon] = useState({
    icon: taskStatus?.label || 'status',
    color: taskStatus?.color || 'gray_06',
    value: taskStatus?.value || 'BACKLOG',
  });
  const [deadline, setDeadline] = useState(treeData[dataId]?.due_time);

  const updateTaskList = (taskId, params, action) => {
    treeData[taskId] = { ...treeData[taskId], ...params };
    if (action) {
      setToastInfo({
        isLoading: false,
        text: `${action} has been changed`,
        actionText: 'Ok',
      });
    }
  };

  const openBoard = () => {
    navigate(`/${boardAssignId}`);
  };

  const changeStatus = (taskId, tasksStatus) => {
    updateTaskList(taskId, { status: tasksStatus }, 'Status');
    const statusParams = {
      id: taskId,
      status: tasksStatus,
    };
    dataServices.updateLayers(taskId, statusParams).then(() => {
      updateStatus(tasksStatus, taskId);
    });
  };

  const selectStatus = (item) => {
    setOpenMenu(null);
    setStatusIcon({ icon: item.label, color: item.color, value: item.value });
    if (item.label === 'done') setIsDone(true);
    else setIsDone(false);
    changeStatus(dataId, item.value);
  };

  const addTasksProject = (parentId) => {
    const newChild = {
      ...layerProps,
      id: uuidv4(),
      title: [{ text: 'TASKS' }],
      layer_type: 'TASK',
      color: 'PINK',
      parent: parentId,
      order: [],
    };
    dataServices.addLayers(newChild).then(
      setTimeout(() => {
        dataServices
          .relocate(dataId, {
            parent: newChild.id,
            previous: null,
          })
          .then(() =>
            setToastInfo({
              ...toastInfo,
              isLoading: false,
              text: 'Task Assigned',
              actionText: 'Okay',
              action: null,
            }),
          );
      }, [1000]),
    );
  };

  const selectBoard = (id) => {
    updateTaskList(dataId, { board_id: id });
    setOpenMenu('');
    dataServices.getLayerById(id).then((layer) => {
      if (layer.data.order.length) {
        dataServices
          .getLayerChildren(id, {
            layer_type__in: 'TASK',
          })
          .then((children) => {
            treeData[dataId].parent_title[0].text = 'TASKS';
            const firstProject = children.data.find(
              (i) => i.id === layer.data.order[0],
            );
            if (firstProject.title[0].text === 'TASKS') {
              dataServices
                .relocate(dataId, {
                  parent: firstProject.id,
                  previous: null,
                })
                .then(() => {
                  setOpenMenu('');
                  setToastInfo({
                    ...toastInfo,
                    isLoading: false,
                    text: 'Task Assigned',
                    actionText: 'Okay',
                    action: null,
                  });
                });
            } else addTasksProject(id);
          });
      } else addTasksProject(id);
    });
  };

  const warnIfNotAssigned = () => {
    if (!boardAssignId)
      setToastInfo({
        text: 'Before doing such action, assign a board to task!',
        actionText: 'Ok',
      });
  };

  const saveDatePickerData = (startDate, dueDate, hasTime, reminder) => {
    setDeadline(dueDate);
    updateTaskList(
      dataId,
      {
        start_time: startDate
          ? new Date(startDate.getTime()).toISOString()
          : null,
        due_time: new Date(dueDate.getTime()).toISOString(),
        due_time_has_time: hasTime,
        reminder,
      },
      'Deadline',
    );

    dataServices.updateLayers(dataId, {
      start_time: startDate,
      due_time: dueDate,
      due_time_has_time: hasTime,
      reminder,
    });
  };

  const hasPermission = () => {
    setToastInfo({
      text: `Task is not yours, You don't have permission`,
      actionText: 'Ok',
    });
  };

  const handleBoard = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    if (boardAssignId) {
      openBoard();
      return;
    }
    setOpenMenu('board');
  };

  const openStatusMenu = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    setOpenMenu('status');
  };

  const openTagMenu = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }

    if (!boardAssignId) {
      warnIfNotAssigned();
      return;
    }
    setOpenMenu('tag');
  };

  const openMembersMenu = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    if (boardAssignId) {
      setOpenMenu('oldMember');
    }
  };

  const openDeadlineMenu = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    setOpenMenu('date');
  };

  const handleDelete = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    deleteTask(dataId);
  };

  const handleDuplicate = () => {
    if (!isTaskMine) {
      hasPermission();
      return;
    }
    duplicateTask(dataId, type, tasksDate);
  };
  return (
    <tr className="task-table-card task-table-tr">
      <td className="task-table-card-number task-table-td font-14-reg">
        {number}
      </td>
      <td className="task-table-td">
        <div className="task-table-card-name-container">
          <div
            className={`task-table-card-status-icon-container cursor-pointer 
              ${isDone ? 'task-table-card-status-icon-container-done' : ''}`}
            onClick={() => {
              setIsDone(!isDone);
              selectStatus(
                isDone ? floatMenuStatus.BACKLOG : floatMenuStatus.DONE,
              );
            }}
          >
            {SvgSelector(
              'checkMark',
              `${isDone ? 'task-table-card-status-done' : 'task-table-card-status-icon'}`,
            )}
          </div>
          <span
            className={`task-table-card-name ${taskName ? 'font-16-bold' : 'font-16-reg '}`}
            style={{
              textDecoration: isDone ? 'line-through' : '',
              color: !taskName ? 'var(--gray_01)' : '',
            }}
          >
            {taskName || 'Add text'}
          </span>
        </div>
      </td>
      <td className="task-table-td" style={{ textAlign: 'left' }}>
        <div
          className="task-table-card-board-container cursor-pointer"
          onClick={() => handleBoard()}
        >
          {boardAssignName ? (
            <span className="font-14-med task-table-card-board-name">
              &lt;| {boardAssignName}
            </span>
          ) : (
            <span className="font-14-med task-table-card-board-name">
              &lt;| Select Board
            </span>
          )}
          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'board'}
            state="topToDown"
          >
            <div className="table-card-board-pos">
              <MenuSelectBoard selectBoard={selectBoard} />
            </div>
          </AnimationSlide>
        </div>
      </td>
      <td className="task-table-td">
        <div className="task-table-card-parent-container">
          <span
            className="task-table-card-parent font-14-med"
            style={{ backgroundColor: nodeColors[parentColor] }}
          >
            {parentName || 'NONE'}
          </span>
        </div>
      </td>
      <td className="task-table-td">
        <div className="task-table-card-status">
          <div
            className="task-table-card-status-container cursor-pointer"
            style={{ backgroundColor: `var(--${statusIcon.color})` }}
            onClick={() => openStatusMenu()}
          >
            {SvgSelector(statusIcon.icon, `status-icon`)}
            <AnimationSlide
              openMenu={setOpenMenu}
              show={openMenu === 'status'}
              state="topToDown"
            >
              <div className="task-table-status-menu">
                <StatusMenu state={statusIcon.value} setState={selectStatus} />
              </div>
            </AnimationSlide>
          </div>
        </div>
      </td>
      <td className="task-table-td">
        <div
          className="task-board-tag-container cursor-pointer"
          onClick={() => openTagMenu()}
        >
          {tagName ? (
            <div
              className="task-board-new-tag font-14-med cursor-pointer"
              style={{
                backgroundColor: nodeColors[tagColor.setColor],
                borderRadius: 3,
                padding: '0 3px',
              }}
            >
              {tagName}
            </div>
          ) : (
            <div className="task-board-tag font-14-med">Tag</div>
          )}
          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'tag'}
            state="topToDown"
          >
            <div className="task-table-tag-menu">
              <TagMenu task={dataId} directReq />
            </div>
          </AnimationSlide>
        </div>
      </td>
      <td className="task-table-td">
        <div className="font-14-reg cursor-pointer">
          {members?.length > 0 ? (
            <div
              className="task-table-card-members-container"
              onClick={() => openMembersMenu()}
            >
              {members?.slice(0, 3).map((member) => {
                return (
                  <Avatar
                    size={20}
                    name={member.email}
                    font="font-14-reg"
                    key={member.email}
                  />
                );
              })}
              {members?.length > 3 && (
                <div className="cursor-pointer font-14-reg task-table-card-more-members">
                  +{(members?.length || 0) - 3}
                </div>
              )}
            </div>
          ) : (
            <div
              className="task-table-card-new-date"
              onClick={() =>
                boardAssignId ? setOpenMenu('newMembers') : warnIfNotAssigned()
              }
            >
              members
            </div>
          )}
          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'newMembers'}
            state="topToDown"
          >
            <div className="task-table-select-member-menu-pos">
              <MenuSelectMember
                taskId={dataId}
                data={boardAssign?.members}
                taskData={treeData[dataId]}
                setOpenMenu={setOpenMenu}
                updateTaskList={updateTaskList}
                setCheckMembers={setCheckMembers}
              />
            </div>
          </AnimationSlide>

          <AnimationSlide
            openMenu={setOpenMenu}
            show={openMenu === 'oldMember'}
            state="topToDown"
          >
            <div className="task-table-more-member-pos">
              <MenuMemberList taskId={dataId} setOpenMenu={setOpenMenu} />
            </div>
          </AnimationSlide>
        </div>
      </td>
      <td className="task-table-td">
        <div
          className="font-14-med cursor-pointer"
          onClick={() => openDeadlineMenu()}
        >
          <div>
            {!deadline ? (
              <div className="task-table-card-new-date">Deadline</div>
            ) : (
              <div
                className="task-table-card-exist-date"
                style={{
                  color:
                    deadlineCal(deadline)[1] === 'alert'
                      ? 'var(--red)'
                      : 'var(--gray_01)',
                }}
              >
                {deadlineCal(deadline)[0]}
              </div>
            )}
            <AnimationSlide
              openMenu={setOpenMenu}
              show={openMenu === 'date'}
              state="topToDown"
            >
              {isProUser ? (
                <div style={{ position: 'absolute', top: 15, right: -14 }}>
                  <DatePicker node={dataId} onClose={saveDatePickerData} />
                </div>
              ) : (
                <div className="task-table-pro-card-pos">
                  <ProOptionCard
                    closeMenu={setOpenMenu}
                    proText="Want to add deadline? Sign up for a tariff level right now!"
                  />
                </div>
              )}
            </AnimationSlide>
          </div>
        </div>
      </td>
      <td className="task-table-td">
        <div className="tasks-table-actions-container">
          <div className="cursor-pointer" onClick={() => handleDelete()}>
            {SvgSelector('delete', 'delete-task-icon')}
          </div>
          <div className="cursor-pointer" onClick={() => handleDuplicate()}>
            {SvgSelector('copy', 'duplicate-task-icon')}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default TaskTableCard;
